import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";

import { isAdmin } from "@/lib/permissions";

import { CurrentUserContext } from "@/components/providers/CurrentUserProvider";
import { Seo } from "@/components/Seo";
import { Spinner } from "@/components/Spinner";

import { pageRoutes } from "@/constant/pageRoutes";

function HomePage() {
  const { replace } = useRouter();
  const { user } = useContext(CurrentUserContext);

  useEffect(() => {
    if (isAdmin(user) && !isMobile) {
      replace(pageRoutes.adminDashboard);
      return;
    }

    replace(pageRoutes.home);
  }, [replace, user]);

  return (
    <>
      <Seo templateTitle="Home" />
      <Spinner />
    </>
  );
}

export default HomePage;
