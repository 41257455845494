import { useContext } from "react";

import { CurrentUserContext } from "@/components/providers/CurrentUserProvider";

import { isAdmin } from "../isAdmin";
import { isStaff } from "../isStaff";

export const useCurrentUserPermissionChecker = () => {
  const { user: currentUser } = useContext(CurrentUserContext);

  const isCurrentUserAdmin = isAdmin(currentUser);
  const isCurrentUserStaff = isStaff(currentUser);

  return {
    canCreateCompetition: isCurrentUserStaff,
    canEditCompetition: isCurrentUserStaff,
    canSpinRaffle: isCurrentUserAdmin,
    canCreateUser: isCurrentUserAdmin,
    canCreateAnnouncement: isCurrentUserAdmin,
  };
};
